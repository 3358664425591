/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Download_White_Svg, Wordmark } from "../../content/assets";
import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { sendFBConversionEvent } from "../../services/pixel";
import { promo_purchase_success, subscription_success } from "../../services/mixpanel/mixpanel";
import { navigate } from "gatsby-link";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { BasicLayout } from "../../layouts/BasicLayout";

export default (data: any) => {
  const textTransition = React.createRef() as any;
  const { search } = data.location;
  const { promoName } = useOnboardingContext();

  console.log("hit here on email notification ");
  useEffect(() => {

    if (promoName) {
      promo_purchase_success();
    }

    if (search) {
      const urlParams = new URLSearchParams(search);
      const subscriptionType = urlParams.get("subscription_type");
      const user = urlParams.get("user");
      if (subscriptionType && user) {
        const yearly_price = 99.99;
        const monthly_price = 15.99;
        const free = 0;
        const value = subscriptionType === "yearly" || subscriptionType === "discount" || subscriptionType === "coupon" ? 
          yearly_price :
          subscriptionType === "monthly" ?
            monthly_price :
            free;
        sendFBConversionEvent({ subscription_type: subscriptionType, value, uid: user });
        subscription_success({ subscription_type: subscriptionType, uid: user });
      }
      // refresh browser and clear search param to prevent issue w/ duplicate event being sent
      navigate("/subscription/complete");
    }
  });

  return (
    <BasicLayout>
      <AppContainer ref={textTransition}>
        <TextContainer>
          <Logo src={Wordmark} alt="image" />
          <Header>Great! Thanks for subscribing. </Header>
          <div>
            <BodyHeader>To get started:</BodyHeader>
            <Body>
              1.
              <a href="https://apps.apple.com/app/apple-store/id1482780647?mt=8">
                {" "}
                Download the Imprint app
              </a>{" "}
              from the App Store
            </Body>
            <Body>2. Log into your account</Body>
            <Body>3. Start learning visually!</Body>
          </div>
          <Body style={{ margin: "56px 0 16px 0" }}>
            With your premium subscription, you have access to the full Imprint library.
          </Body>
          <ImageContainer href="https://apps.apple.com/app/apple-store/id1482780647?mt=8">
            <img src={Download_White_Svg} alt="download_image" />
          </ImageContainer>
          <Body style={{ marginTop: "56px" }}>
            Questions? Email us at <a href="mailto:info@imprintapp.com">info@imprintapp.com</a>
          </Body>
        </TextContainer>
      </AppContainer>
    </BasicLayout>
  );
};

const fadeIn = keyframes`
  0% { transform: translateX(15px); opacity: 0.25; }
  100% { transform: translateX(0); opacity: 1; }
`;

const AppContainer = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: #eef2f6;
  animation: ${fadeIn} 0.5s forwards linear;
`;

const TextContainer = styled.div`
  margin: 0 24px;
  margin-top: 5%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Logo = styled.img`
  display: flex;
  margin: auto;
  margin-bottom: 100px;
  @media ${mobileSize} {
    height: 21px;
    margin-top: 15%;
    margin: 20px auto 10px;
  }
`;

const Header = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 24px;
  line-height: 28px;
  color: #2d7dd2;
`;

const BodyHeader = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #292032;
  margin-bottom: 24px;
`;
const Body = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 15px;
  line-height: 24px;
  color: rgba(113, 107, 120, 1);
  a {
    color: rgba(113, 107, 120, 1);
    text-decoration: underline;
  }
`;

const ImageContainer = styled.a`
  display: flex;
  margin: auto;
  margin-top: 30px;

  img {
    width: 225px;
  }
`;
